<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'agents'}">Agents</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Deleted Agents</a></li>
    </ol>
    <h1 class="page-header">Deleted Agents</h1>
    <panel noButton="true" title="Deleted Agents" variant="panel-default">
      <loading v-if="!is_loaded"/>
      <template v-else>
        <table class="table table-striped table-responsive-sm">
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Registration No</th>
            <th>Type</th>
            <th>Status</th>
            <th>Deleted At</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(agent, i) in agents"
              :key="i"
          >
            <td>{{ pagination.from + i }}</td>
            <td>{{ agent.name }}</td>
            <td>{{ agent.email }}</td>
            <td>{{ agent.phone }}</td>
            <td>{{ agent.crp }}</td>
            <td>{{ agent.agent_type }}</td>
            <td>{{ agent.active ? 'Active' : 'Inactive' }}</td>
            <td>{{ agent.deleted_at }}</td>
            <td>
              <a
                  v-if="$can.view($store.getters.getAuthUser, 'restore-agents')"
                  class="btn btn-success btn-sm"
                  href="#"
                  @click.prevent="restoreAgent(agent.id)"
              >Restore</a>
            </td>
          </tr>
          <tr v-if="!agents.length">
            <td :colspan="9">
              <div class="alert alert-info">
                No deleted agents found
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <pagination
            v-if="pagination.last_page > 1"
            :offset="5"
            :pagination="pagination"
            @paginate="fetch"
        />
      </template>
    </panel>
  </div>
</template>

<script>
export default {

  data() {
    return {
      agents: [],
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/agents/deleted?page=' + this.pagination.current_page).then(response => {
        this.is_loaded = true;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.agents = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);

      });
    },
    restoreAgent(agent_id) {
      this.$swal({
        title: 'Are you sure?',
        text: "This will restore the agent user account",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, restore it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.post('/api/v1/agents/' + agent_id + '/restore').then(() => {
            this.$toastr.s('Agent restored successfully.', 'Success');
            this.$router.replace({name: 'agents'});
          });
        }
      })
    },
  }
}
</script>
